
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutU06dK8EfeHMeta } from "/app/pages/[language]/about.vue?macro=true";
import { default as confirmQruBTrnQfiMeta } from "/app/pages/[language]/account/confirm.vue?macro=true";
import { default as forgotvRUVpDYO6aMeta } from "/app/pages/[language]/account/forgot.vue?macro=true";
import { default as loginJjwhg7UbzPMeta } from "/app/pages/[language]/account/login.vue?macro=true";
import { default as my_45couponsv4cGYS5hI0Meta } from "/app/pages/[language]/account/my-coupons.vue?macro=true";
import { default as my_45infoTuEf3x5gs7Meta } from "/app/pages/[language]/account/my-info.vue?macro=true";
import { default as indexPeV8EcUHGxMeta } from "/app/pages/[language]/account/my-orders/[id]/index.vue?macro=true";
import { default as reviewibljo3Fv3mMeta } from "/app/pages/[language]/account/my-orders/[id]/review.vue?macro=true";
import { default as indexQunHSop19KMeta } from "/app/pages/[language]/account/my-orders/index.vue?macro=true";
import { default as my_45outspot_45plusVEYE8H5v8vMeta } from "/app/pages/[language]/account/my-outspot-plus.vue?macro=true";
import { default as notificationssFOJjRj56eMeta } from "/app/pages/[language]/account/notifications.vue?macro=true";
import { default as passwordJWE1zycUbxMeta } from "/app/pages/[language]/account/password.vue?macro=true";
import { default as registerwN7J2Bcl58Meta } from "/app/pages/[language]/account/register.vue?macro=true";
import { default as indexkimvN0QfZkMeta } from "/app/pages/[language]/account/unsubscribe/index.vue?macro=true";
import { default as report036mnBh6zZMeta } from "/app/pages/[language]/account/unsubscribe/report.vue?macro=true";
import { default as claim_45couponK1SRXl4Ih4Meta } from "/app/pages/[language]/claim-coupon.vue?macro=true";
import { default as indexf29a4ekf9KMeta } from "/app/pages/[language]/deals/[slug]/index.vue?macro=true";
import { default as details1FmwBHFAceMeta } from "/app/pages/[language]/deals/[slug]/order/details.vue?macro=true";
import { default as finishedxAcTXOQKhnMeta } from "/app/pages/[language]/deals/[slug]/order/finished.vue?macro=true";
import { default as indexGZ2CtzegwyMeta } from "/app/pages/[language]/deals/[slug]/order/index.vue?macro=true";
import { default as reviewsHOwNJhCx6yMeta } from "/app/pages/[language]/deals/[slug]/reviews.vue?macro=true";
import { default as _91slug_9379r4IcNSrkMeta } from "/app/pages/[language]/help/[slug].vue?macro=true";
import { default as indexNLQIHJg711Meta } from "/app/pages/[language]/help/index.vue?macro=true";
import { default as indexS5WyhbOij0Meta } from "/app/pages/[language]/index.vue?macro=true";
import { default as nuvei_45helperSjbhIKNqixMeta } from "/app/pages/[language]/nuvei-helper.vue?macro=true";
import { default as indexeDFrCJmGWCMeta } from "/app/pages/[language]/onboard/index.vue?macro=true";
import { default as notificationsnChuxWrFC1Meta } from "/app/pages/[language]/onboard/notifications.vue?macro=true";
import { default as indexkGe0ECwdXVMeta } from "/app/pages/[language]/outspot-plus/index.vue?macro=true";
import { default as detailsdt90XU3RNkMeta } from "/app/pages/[language]/outspot-plus/order/details.vue?macro=true";
import { default as finishedADdDBx7oc1Meta } from "/app/pages/[language]/outspot-plus/order/finished.vue?macro=true";
import { default as promo3VmeVrmHqkMeta } from "/app/pages/[language]/promo.vue?macro=true";
import { default as index9In0y2RwlVMeta } from "/app/pages/[language]/subscription/index.vue?macro=true";
import { default as sustainabilityClUf5IkQjdMeta } from "/app/pages/[language]/sustainability.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as readywgYXjzIKUXMeta } from "/app/pages/ready.vue?macro=true";
export default [
  {
    name: "language-about",
    path: "/:language()/about",
    component: () => import("/app/pages/[language]/about.vue")
  },
  {
    name: "language-account-confirm",
    path: "/:language()/account/confirm",
    component: () => import("/app/pages/[language]/account/confirm.vue")
  },
  {
    name: "language-account-forgot",
    path: "/:language()/account/forgot",
    component: () => import("/app/pages/[language]/account/forgot.vue")
  },
  {
    name: "language-account-login",
    path: "/:language()/account/login",
    component: () => import("/app/pages/[language]/account/login.vue")
  },
  {
    name: "language-account-my-coupons",
    path: "/:language()/account/my-coupons",
    component: () => import("/app/pages/[language]/account/my-coupons.vue")
  },
  {
    name: "language-account-my-info",
    path: "/:language()/account/my-info",
    component: () => import("/app/pages/[language]/account/my-info.vue")
  },
  {
    name: "language-account-my-orders-id",
    path: "/:language()/account/my-orders/:id()",
    component: () => import("/app/pages/[language]/account/my-orders/[id]/index.vue")
  },
  {
    name: "language-account-my-orders-id-review",
    path: "/:language()/account/my-orders/:id()/review",
    meta: reviewibljo3Fv3mMeta || {},
    alias: ["/:language/orders/rate?:id"],
    component: () => import("/app/pages/[language]/account/my-orders/[id]/review.vue")
  },
  {
    name: "language-account-my-orders",
    path: "/:language()/account/my-orders",
    component: () => import("/app/pages/[language]/account/my-orders/index.vue")
  },
  {
    name: "language-account-my-outspot-plus",
    path: "/:language()/account/my-outspot-plus",
    component: () => import("/app/pages/[language]/account/my-outspot-plus.vue")
  },
  {
    name: "language-account-notifications",
    path: "/:language()/account/notifications",
    component: () => import("/app/pages/[language]/account/notifications.vue")
  },
  {
    name: "language-account-password",
    path: "/:language()/account/password",
    component: () => import("/app/pages/[language]/account/password.vue")
  },
  {
    name: "language-account-register",
    path: "/:language()/account/register",
    component: () => import("/app/pages/[language]/account/register.vue")
  },
  {
    name: "language-account-unsubscribe",
    path: "/:language()/account/unsubscribe",
    component: () => import("/app/pages/[language]/account/unsubscribe/index.vue")
  },
  {
    name: "language-account-unsubscribe-report",
    path: "/:language()/account/unsubscribe/report",
    component: () => import("/app/pages/[language]/account/unsubscribe/report.vue")
  },
  {
    name: "language-claim-coupon",
    path: "/:language()/claim-coupon",
    component: () => import("/app/pages/[language]/claim-coupon.vue")
  },
  {
    name: "language-deals-slug",
    path: "/:language()/deals/:slug()",
    meta: indexf29a4ekf9KMeta || {},
    alias: ["/:language/deals/test/:slug"],
    component: () => import("/app/pages/[language]/deals/[slug]/index.vue")
  },
  {
    name: "language-deals-slug-order-details",
    path: "/:language()/deals/:slug()/order/details",
    meta: details1FmwBHFAceMeta || {},
    alias: ["/:language/deals/test/:slug/order/details"],
    component: () => import("/app/pages/[language]/deals/[slug]/order/details.vue")
  },
  {
    name: "language-deals-slug-order-finished",
    path: "/:language()/deals/:slug()/order/finished",
    meta: finishedxAcTXOQKhnMeta || {},
    alias: ["/:language/deals/test/:slug/order/finished"],
    component: () => import("/app/pages/[language]/deals/[slug]/order/finished.vue")
  },
  {
    name: "language-deals-slug-order",
    path: "/:language()/deals/:slug()/order",
    meta: indexGZ2CtzegwyMeta || {},
    alias: ["/:language/deals/test/:slug/order"],
    component: () => import("/app/pages/[language]/deals/[slug]/order/index.vue")
  },
  {
    name: "language-deals-slug-reviews",
    path: "/:language()/deals/:slug()/reviews",
    meta: reviewsHOwNJhCx6yMeta || {},
    alias: ["/:language/deals/test/:slug/reviews"],
    component: () => import("/app/pages/[language]/deals/[slug]/reviews.vue")
  },
  {
    name: "language-help-slug",
    path: "/:language()/help/:slug()",
    component: () => import("/app/pages/[language]/help/[slug].vue")
  },
  {
    name: "language-help",
    path: "/:language()/help",
    component: () => import("/app/pages/[language]/help/index.vue")
  },
  {
    name: "language",
    path: "/:language()",
    component: () => import("/app/pages/[language]/index.vue")
  },
  {
    name: "language-nuvei-helper",
    path: "/:language()/nuvei-helper",
    component: () => import("/app/pages/[language]/nuvei-helper.vue")
  },
  {
    name: "language-onboard",
    path: "/:language()/onboard",
    component: () => import("/app/pages/[language]/onboard/index.vue")
  },
  {
    name: "language-onboard-notifications",
    path: "/:language()/onboard/notifications",
    component: () => import("/app/pages/[language]/onboard/notifications.vue")
  },
  {
    name: "language-outspot-plus",
    path: "/:language()/outspot-plus",
    component: () => import("/app/pages/[language]/outspot-plus/index.vue")
  },
  {
    name: "language-outspot-plus-order-details",
    path: "/:language()/outspot-plus/order/details",
    component: () => import("/app/pages/[language]/outspot-plus/order/details.vue")
  },
  {
    name: "language-outspot-plus-order-finished",
    path: "/:language()/outspot-plus/order/finished",
    component: () => import("/app/pages/[language]/outspot-plus/order/finished.vue")
  },
  {
    name: "language-promo",
    path: "/:language()/promo",
    component: () => import("/app/pages/[language]/promo.vue")
  },
  {
    name: "language-subscription",
    path: "/:language()/subscription",
    component: () => import("/app/pages/[language]/subscription/index.vue")
  },
  {
    name: "language-sustainability",
    path: "/:language()/sustainability",
    component: () => import("/app/pages/[language]/sustainability.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "ready",
    path: "/ready",
    component: () => import("/app/pages/ready.vue")
  }
]